import { Title } from '@solidjs/meta';
import { Container, Heading, Page, Section } from '@troon/ui';
import { useParams } from '@solidjs/router';
import { FrequentlyAskedQuestions } from '../../../../components/faqs';

export default function FaqList() {
	const params = useParams<{ id: string }>();

	return (
		<Container>
			<Page>
				<Title>FAQ Preview | Previews | Troon</Title>
				<Heading as="h1">FAQ Preview</Heading>

				<Section class="rounded border border-neutral">
					<FrequentlyAskedQuestions id={params.id} />
				</Section>
			</Page>
		</Container>
	);
}
